import path from 'path';
import { howItWorks as pageMeta } from '../data/page_meta.yml';
import PagePost from '../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { siteMeta } from '../data/site_meta.yml';
import PageWrapper from '../components/DefaultPageWrapper';
import {
    SEO,
} from '@brandedholdings/react-components';

class HowItWorks extends React.Component {
    getChildContext() {
        return { siteMeta };
    }

    render() {
        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);

        const pageImages = {};
        page.data.pageImages.edges.forEach(({ node }) => pageImages[path.basename(node.absolutePath).split('.')[0]] = node);


        return (
            <PageWrapper>
                <SEO post={ post } />
                <BackgroundImage
                    className="background__gatsby background background__graphic"
                    fluid={pageImages.hero.image.fluid}
                >
                    <div className="layout">
                        <div className="layout-content center">
                            <h1>How it Works</h1>
                        </div>
                    </div>
                </BackgroundImage>
                <div className="background background__white">
                    <div className="layout">
                        <div className="layout-content">
                            <h3>About {siteMeta.nameShort}</h3>
                            <p>{siteMeta.nameShort} is an intelligent marketplace designed to help you find a personal loan lender now. With {siteMeta.nameShort} you can find hassle-free financial assistance from reputable lenders. The entire process is safe, secure and completely online. No one to deal with in person, {siteMeta.nameShort} does not charge you any fees to use our service.</p>
                            <hr />
                            <h3>Submit your request in just a few minutes</h3>
                            <p>Once you&rsquo;ve completed the form, your information is reviewed instantaneously by lenders to determine if they would like to work with you. If a lender decides they would like to work with you, you will be redirected to their website where you complete the lender&rsquo;s forms, review the loan terms, and if agreeable to you, accept the loan to collect your funds as early as the next business day.*</p>
                            <hr />
                            <h3>Minimum Qualifications</h3>
                            <ul className="list">
                                <li>18 years or older</li>
                                <li>Valid Social Security Number</li>
                                <li>U.S. citizen or permanent resident</li>
                                <li>Employed, self-employed, or receiving benefits</li>
                                <li>Most lenders require a valid checking account to deposit your funds</li>
                            </ul>
                            <p>Meeting these requirements does not necessarily guarantee you will find a lender.</p>
                        </div>
                    </div>
                </div>
            </PageWrapper>
        );
    }
};

HowItWorks.childContextTypes = {
    siteMeta: PropTypes.object
};

export default HowItWorks;

export const query = graphql`
    {
        pageImages: allFile(filter: {absolutePath: {regex: "^static/assets/images/covers/"}}) {
            edges {
                node {
                    id
                    absolutePath
                    image: childImageSharp {
                        fluid(
                            quality: 90, 
                            maxWidth: 2000,
                            traceSVG: { background: "#0ba7e2", color: "#0ba7e2" }
                        ) {
                            # base64
                            tracedSVG
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    }
`;